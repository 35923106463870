<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

<div class="section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 contents">
        <div class="row justify-content-center">
          <div class="col-md-8 col-sm-12">
            <div class="mb-5">
              <h1>Account Deactivation</h1>
              <h4>We regret to see you go, are you sure you want to deactivate your account?</h4>
            </div>

            <div class="mb-5">We only offer account deactivation at the moment due to security, fraud prevention, and regulatory compliance.</div>

            <ng-container *ngIf="currUser != null; else alterContent">
              <button type="button" class="btn btn-axp-theme" (click)="deactivateAccount()">Deactivate Account</button>
            </ng-container>
            <ng-template #alterContent>
              <a class="btn btn-axp-theme" href="/login">Login to Deactivate Account</a>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
