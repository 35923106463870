import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Credential, User } from '../models';

const API_URL = environment.apiUrl;
const httpOption = {
  headers: new HttpHeaders({
    'secretKey': environment.apiKey
  })
}
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getCurrentLocation(): Observable<any> {
    return this.http.get('http://ip-api.com/json');
  }

  checkEmail(email: string): Observable<any> {
    return this.http.post(`${API_URL}/account/email/check/${email}`, {}, { responseType: 'text' });
  }

  login(credentials: Credential): Observable<any> {
    return this.http.post(`${API_URL}/account/login`, credentials, { responseType: 'text' });
  }

  register(userDetails: User): Observable<any> {
    return this.http.post(`${API_URL}/account`, userDetails, { responseType: 'text' });
  }

  deactivateAccount(id: any) {
    return this.http.put(`${API_URL}/account/delete-account/${id}`, {});
  }

  getStates(): Observable<any> {
    return this.http.get(`${API_URL}/account/states`);
  }

  getCitiesCountriesByState(stateProvCode: string): Observable<any> {
    return this.http.get(`${API_URL}/account/cities-countries/${stateProvCode}`);
  }

  generateInvoiceNo(): Observable<any> {
    return this.http.get(`${API_URL}/invoice-service/generate-invoice-number`);
  }

  createBooking(invoiceForm: any): Observable<any> {
    return this.http.post(`${API_URL}/invoice-service/create`, invoiceForm);
  }

  getInvoices(searchFilter: any): Observable<any> {
    const clientTimeZone = localStorage.getItem(environment.CLIENT_TIMEZONE);
    return this.http.post(`${API_URL}/invoice-service`, searchFilter, { headers: { TimeZone: clientTimeZone } });
  }

  getReceiverHistoryList(): Observable<any> {
    return this.http.get(`${API_URL}/receiver-history`);
  }

  getAddressByZipCode(zipCode: string): Observable<any> {
    return this.http.get(`${API_URL}/zip-code/address/${zipCode}`);
  }

  getPhAddressByZipCode(zipCode: string): Observable<any> {
    return this.http.get(`${API_URL}/zip-code/ph-address/${zipCode}`);
  }

  trackInvoice(invoice: string): Observable<any> {
    return this.http.get(`${API_URL}/tracking/invoice/${invoice}`);
  }

  customerInvoices(customerId: string): Observable<any> {
    return this.http.get(`${API_URL}/invoice-service/transaction-listby/${customerId}`);
  }

  getInvoiceStatuses(invoice: string): Observable<any> {
    let timezone = '';

    timezone = localStorage.getItem(environment.CLIENT_TIMEZONE);
    if (!timezone) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    return this.http.get(`${API_URL}/invoice-service/${invoice}`, { headers: { TimeZone: timezone } });
  }

  getInvoiceNotes(invoiceNo: string): Observable<any> {
    let timezone = '';

    timezone = localStorage.getItem(environment.CLIENT_TIMEZONE);
    if (!timezone) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    return this.http.get(`${API_URL}/invoice-service/notes/${invoiceNo}`, { headers: { TimeZone: timezone } });
  }

  sendContactUs(form: any): Observable<any> {
    return this.http.post(`${API_URL}/contactus`, form);
  }

  sendNewsLetterSubscription(form: any): Observable<any> {
    return this.http.post(`${API_URL}/newsletter`, form);
  }

  // Payments 
  getUserPayments(): Observable<any> {
    return this.http.get(`${API_URL}/payment`);
  }

  getUserPaymentTypes(): Observable<any> {
    return this.http.get(`${API_URL}/payment/types`);
  }

  addUserPayment(param): Observable<any> {
    return this.http.post(`${API_URL}/payment`, param);
  }

  cardPayment(invoiceForm: any): Observable<any> {
    return this.http.post(`${API_URL}/payment/card`, invoiceForm, httpOption);
  }

  getEncryptedInvoice(id: string): Observable<any> {
    return this.http.get(`${API_URL}/invoice-service/encrypted/${id}`);
  }
}
