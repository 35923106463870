import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/models';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-deactivation',
  templateUrl: './account-deactivation.component.html',
  styleUrls: ['./account-deactivation.component.scss'],
})
export class AccountDeactivationComponent implements OnInit {
  currUser: User;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.currUser = this.authService.getUser();
  }

  deactivateAccount(): void {
    Swal.fire({
      icon: 'warning',
      title: 'Deactivate Account',
      text: 'Are you sure you want to deactivate your account? This cannot be undone later.',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#de1e07'
    }).then((res: any) => {
      if(res.isConfirmed) {
        this.dataService.deactivateAccount(this.currUser.id)
          .subscribe((result: any) => {
            Swal.fire({
              icon: 'success',
              title: 'Account has been deactivated.'
            });

            this.logout();
          }, (error) => {
            console.log(error)

            Swal.fire({
              icon: 'error',
              title: 'Oops! Something went wrong. ' + error
            });
          });
      }
    });
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('home', { replaceUrl: true });
  }
}
