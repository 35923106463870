import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { BranchesComponent } from './pages/branches/branches.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { ServicesComponent } from './pages/services/services.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { BalikbayanBoxComponent } from './pages/axp/balikbayan-box/balikbayan-box.component';
import { RemittanceDetailsComponent } from './pages/axp/remittance-details/remittance-details.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { SharedModule } from './shared/shared.module';
import { InvoiceComponent } from './sms/invoice/invoice.component';
import { PickupComponent } from './sms/templates/pickup/pickup.component';
import { DropoffComponent } from './sms/templates/dropoff/dropoff.component';
import { HtmlPipe } from './pipes/html.pipe';
import { CustomerActivationComponent } from './account/customer-activation/customer-activation.component';
import { TermsAndConditionsShipmentComponent } from './pages/terms-and-conditions-shipment/terms-and-conditions-shipment.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { FremanPrivacyPolicyComponent } from './pages/freman-privacy-policy/freman-privacy-policy.component';
import { QuillModule } from 'ngx-quill';
import { BlogListComponent } from './pages/blogs/blog-list/blog-list.component';
import { BlogPostComponent } from './pages/blogs/blog-post/blog-post.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DeeplinkComponent } from './deeplink/deeplink.component';
import { environment } from 'src/environments/environment';
import { FacebookLoginProvider, SocialAuthService, SocialAuthServiceConfig } from 'angularx-social-login';
import { TransactionHistoryComponent } from './pages/transaction-history/transaction-history.component';
import { ViewTransactionDetailsComponent } from './pages/transaction-history/view-transaction-details/view-transaction-details.component';
import { TransactionTableComponent } from './pages/transaction-history/transaction-table/transaction-table.component';
import { FremanOnlinePaymentComponent } from './pages/freman-online-payment/freman-online-payment.component';
import { DownloadComponent } from './pages/download/download.component';
import { JobApplicationComponent } from './pages/job-application/job-application.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { DevTestingComponent } from './pages/dev-testing/dev-testing.component';
import { ManualsComponent } from './pages/manuals/manuals.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ResourceUrlPipe } from './pipes/resource-url.pipe';
import { AccountDeactivationComponent } from './pages/account-deactivation/account-deactivation.component';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HomeComponent,
    BranchesComponent,
    TrackingComponent,
    ServicesComponent,
    AboutUsComponent,
    ContactUsComponent,
    BalikbayanBoxComponent,
    RemittanceDetailsComponent,
    InvoiceComponent,
    PickupComponent,
    DropoffComponent,
    CustomerActivationComponent,
    HtmlPipe,
    TermsAndConditionsShipmentComponent,
    PrivacyPolicyComponent,
    FremanPrivacyPolicyComponent,
    BlogListComponent,
    BlogPostComponent,
    DeeplinkComponent,
    TransactionHistoryComponent,
    ViewTransactionDetailsComponent,
    TransactionTableComponent,
    FremanOnlinePaymentComponent,
    DownloadComponent,
    JobApplicationComponent,
    DevTestingComponent,
    ManualsComponent,
    ResourceUrlPipe,
    AccountDeactivationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTooltipModule,
    SharedModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatIconModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    QuillModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    DatePipe,
    DecimalPipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.fbAppID)
          }
        ],
        orError: (err) => {
          console.log(err)
        },
      } as SocialAuthServiceConfig
    },
    SocialAuthService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
